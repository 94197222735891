.filedrop {
    @include light-shadow3D;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 300px;
    height: 80px;
    border-radius: 10px;
    outline: none;
    color: #eee;
    background-color: $green;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
        @include light-shadow3D-focus;
    }

    &--focus {
        @include light-shadow3D-focus;
    }

    &--accept {
        box-shadow: 0px 8px 10px -5px rgba(66, 68, 90, 1), inset 0 0 10px 5px rgb(0, 161, 86);
    }
    
    &--reject {
        box-shadow: 0px 8px 10px -5px rgba(66, 68, 90, 1), inset 0 0 10px 5px $red;
    }
}