.btn {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    &--delete-icon {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        // color: black;
        color: #eee;
        background-color: $red;
        transition: 0.2s;
    
        &:hover {
            background-color: darken($red, 10%);
        }
    }

    &--delete {
        @extend .form__submit;
        margin: 0;
        color: #eee;
        background-color: $red;

        &-cover {
            position: absolute;
            right: 10px;
            bottom: 10px;
            color: #eee;
            background-color: $red;
        }

        &:hover {
            color: white;
        }
    }

    &--confirm {
        @extend .form__submit;
        margin: 0 20px 0 0;
        color: #eee;

        &:hover {
            color: white;
        }
    }

    &--close {
        position: absolute;
        right: 20px;
        top: 20px;
        color: $green;
        background-color: transparent;
        transition: 0.2s;

        &:hover {
            color: $dark-green;
        }

        &-icon {
            width: 20px;
            height: 20px;
        }
        
    }
}

.add-btn {
    @include shadow3D;
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 25px;
    height: 25px;
    padding: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 70px;
    color: $green;
    background-color: #eee;
    cursor: pointer;
    z-index: 100;
    transition: 0.2s;

    &:hover {
        @include shadow3D-hover;
        color: $dark-green;
    }
}