.form {
    &__section {
        margin-bottom: 20px;
    }

    &__title {
        margin-bottom: 6px;
        font-size: 18px;
        color: $dark-green;
    }
    
    &__search {
        @include light-shadow3D;
        padding: 23px;
        background-color: white;
        border: none;
        // border-bottom: 3px solid $green;
        border-radius: 10px;
        outline: none;
        margin: 10px 10px 20px;
        font-size: 16px;
        font-weight: bold;
        color: $dark-green;
        transition: 0.2s;

        &:focus {
            @include light-shadow3D-focus;
        }

        &::placeholder {
            color: lighten($green, 20%);
        }
    }

    &__inp {
        font-family: 'Arial', sans-serif;
        min-width: 200px;
        padding: 10px;
        border: none;
        border-radius: 6px;
        margin-right: 20px;
        outline: none;
        color: $dark-green;
        font-weight: bold;

        &--max {
            width: 100%;
            margin: 0;
        }

        &::placeholder {
            color: $green;
            font-weight: normal;
        }
    }

    &__select {
        @extend .form__inp;
    }

    &__option {
        color: $dark-green;
        font-size: 13px;
        font-weight: bold;
    }

    &__textarea {
        font-family: 'Arial', sans-serif;
        width: 100%;
        min-height: 150px;
        padding: 10px;
        border: none;
        border-radius: 6px;
        outline: none;
        color: $dark-green;
        font-weight: bold;
        resize: vertical;

        &::placeholder {
            color: $green;
            font-weight: normal;
        }
    }

    &__submit {
        @include light-shadow3D;
        font-family: 'Arial', sans-serif;
        display: block;
        padding: 8px 14px;
        border: none;
        border-radius: 6px;
        outline: none;
        margin: 0 auto 20px;
        color: $light-green;
        background-color: $green;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        transition: 0.2s;
        
        &:hover {
            @include light-shadow3D-focus;
            color: $dark-green;
            // background-color: $dark-green;
        }

        &:disabled {
            background-color: lighten($green, 10%);
            color: lighten($light-green, 10%);
            cursor: default;

            &:hover {
                @include light-shadow3D;
            }
        }
    }

    &__file {
        display: none;

        &-label {
            @extend .form__submit;
            width: fit-content;
            margin: 0;
        }
    }

    &__specifications-item {
        margin-bottom: 10px
    }

    &__specifications-add-btn {
        @include light-shadow3D;
        width: 15px;
        height: 15px;
        padding: 10px;
        border-radius: 50%;
        color: $green;
        background-color: white;
        transition: 0.2s;

        &:hover {
            @include light-shadow3D-focus;
            color: $dark-green;
        }
    }

    &__specifications-delete-btn {
        color: $green;
        transition: 0.2s;

        &:hover {
            color: $dark-green;
        }
    }

    &__small {
        display: block;
        margin-bottom: 5px;
    }

    &__card-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;

        @media (min-width: $mobile) {
            justify-content: flex-start;
        }

        &-search {
            display: flex;
            // flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .form__card-list-search-btn {
                display: none;
                @extend .form__submit;
                margin: 10px 10px 20px 0;
                background-color: $green;

                @media (min-width: $sm-mobile) {
                    display: block;
                }
            }
        }
    }

    &__card-item {
        @include light-shadow3D;
        background-position: center;
        background-size: cover;
        width: 90px;
        height: 90px;
        border: 4px solid $light-green;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.2s;

        &.active {
            border: 4px solid #a13000;
            @include light-shadow3D-focus;
        }

        &:hover {
            @include light-shadow3D-focus;
        }
    }

    &__checkbox {
        // margin-right: 4px;
        display: none;

        &-list {
            display: flex;
            flex-wrap: wrap;
        }

        &-item {
            margin-right: 20px;
        }

        &-label {
            display: flex;
            align-items: center;
            color: $dark-green;
            font-weight: bold;
        }

    }

    &__validation-list {
        margin-bottom: 20px;
    }

    .form__more-btn {
        @extend .form__submit;
        display: block;
        margin: 20px auto;
        background-color: $green;

        @media (min-width: $sm-mobile) {
            display: none;
        }
    }
}

.checkbox {
    position: relative;
    width: 16px;
    height: 16px;
    border: 2px solid $dark-green;
    border-radius: 4px;
    margin-right: 3px;
    background-color: $light-green;
    cursor: pointer;

    &__icon {
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        transform: translate(-1px, -1px);
    }
}