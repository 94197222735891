.login-screen {

    &__form {
        display: flex;
        flex-direction: column;
    }

    &__inp {
        margin-bottom: 20px;
    }
}