.menu {
    @include shadow;
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 100vw;
    overflow-x: scroll;

    &::-webkit-scrollbar-track {
        background-color: white;
    }

    &__list {
        display: flex;
        // flex-wrap: wrap;
    }

    &__item {
        &:nth-of-type(1) .menu__link {
            padding-left: 40px;
        }
    }

    &__link {
        display: block;
        text-decoration: none;
        width: max-content;
        padding: 30px 20px 26px;
        border-bottom: 2px solid transparent;
        color: $green;
        font-size: 20px;
        font-weight: bold;

        &:hover {
            color: $dark-green;
        }

        &.active {
            color: $dark-green;
        }
    }

    &__logout {
        margin-left: 50px;
        &-text {
            padding: 30px 20px 26px;
            border-bottom: 2px solid transparent;
            color: $green;
            font-size: 20px;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                color: $dark-green;
            }
        }
    }
}